import { API_BASE_URL, API_CDN_URL } from 'configs/AppConfig';

export const api_base_url = API_BASE_URL;
export const api_base_url_auth = API_BASE_URL + '/v1/auth';
export const api_base_url_panel = API_BASE_URL + '/v1';

export const API_URL = {
  login: api_base_url_auth + '/login',
  forgot: api_base_url_auth + '/reset-password',
  resetPassword: api_base_url_auth + '/submit-reset-password',
  changePassword: api_base_url_auth + '/change-password',

  // Overview

  // Commercials
  pembayar: api_base_url_panel + '/customer',
  shippingAddress: api_base_url_panel + '/shipping-address',
  booking: api_base_url_panel + '/booking-order',
  pickup: api_base_url_panel + '/pickup-order',
  jobOrder: api_base_url_panel + '/job-order',
  invoice: api_base_url_panel + '/invoice',
  tarifUmum: api_base_url_panel + '/',

  // Procurenment
  vendor: api_base_url_panel + '/vendor',
  vendorPrice: api_base_url_panel + '/vendor-price',

  // Operational
  delivery: api_base_url_panel + '/delivery-order',
  voyage: api_base_url_panel + '/voyage',
  containerSize: api_base_url_panel + '/container-size',
  packingList: api_base_url_panel + '/packing-list',

  // Settings
  user: api_base_url_panel + '/user',
  staff: api_base_url_panel + '/employee',
  role: api_base_url_panel + '/user-role',
  company: api_base_url_panel + '/company',
  location: api_base_url_panel + '/location',
  product: api_base_url_panel + '/product',
  account: api_base_url_panel + '/cart-of-account',
  otherCondition: api_base_url_panel + '/other-condition',
  masterCost: api_base_url_panel + '/cost',
  templateCost: api_base_url_panel + '/cost-template',
  commodity: api_base_url_panel + '/commodity',
  unit: api_base_url_panel + '/unit',
  vehicle: api_base_url_panel + '/vehicle',

  // Finace Accounting
  invoiceGroup: api_base_url_panel + '/invoice-group',
  customerPayment: api_base_url_panel + '/customer-payment',
  defaultAccount: api_base_url_panel + '/default-account',
  bukuBesar: api_base_url_panel + '/',
  neracaSaldo: api_base_url_panel + '/',
  neraca: api_base_url_panel + '/',
  labaRugi: api_base_url_panel + '/',
  labaRugiKonsolidasi: api_base_url_panel + '/',
  apCost: api_base_url_panel + '/ap-cost',
  vendorInvoice: api_base_url_panel + '/vendor-invoice',

  // Management
  legal: api_base_url_panel + '/',

  changeCompany: api_base_url_panel + '/auth/change-company',
  profile: api_base_url_panel + '/auth/get',

  uploader: API_CDN_URL,
};
